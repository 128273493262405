import React from 'react'
import {Row, Col} from 'react-materialize'

const Future = () => (
    <div className='About__future'>
        <h3 className='About__title'>Future plans</h3>
        <div>
            <Row>
                <Col>1</Col>
            </Row>
        </div>
    </div>
);

export default Future
